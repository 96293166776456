//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

tr {
  border-bottom: 1px solid #eff2f5;
}

.display-linebreak {
  white-space: pre-line;
}

.bg-prgreen-md {
  @include media-breakpoint-up(md) {
    background-color: $prgreen !important;
  }
}

.bg-prturq-md {
  @include media-breakpoint-up(md) {
    background-color: $prturq !important;
  }
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.apple-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 1.775rem;
  width: 100% !important;
}

.apple-logo i {
  font-size: large !important;
  color: white !important;
}

.apple-button:hover {
  background-color: #333;
}

.link-img-icon {
  stroke: #84B545;
}

#af061f6d-a8e5-4f3d-a5de-6fc0f720326e {
  stroke: #84B545 !important;
}

.chat-messanger-blank {
  background-color: #f2f2f2;
  height: 40px;
}

// Tablet & mobile modes costom css
@media (max-width: 991.98px) {
  .back-button-p {
    padding: 5px 20px !important;
  }

  // #kt_content_container .justify-content-end{
  //   min-height: 200px !important;
  // }

  #kt_toolbar {
    margin-top: 40px;
    position: relative;
  }

  #kt_toolbar::before {
    content: '';
    position: absolute;
    background-color: #f2f2f2;
    /* Add your desired color */
    top: -40px;
    /* Adjust to match the margin-top value to create the colored margin space */
    bottom: 55px;
    left: 0;
    right: 0;
  }

  .dashboard-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #kt_post {
    padding-top: 0px !important;
  }

  .dashboard-container .card,
  .image-head-box,
  .property-head-box,
  .page-header-card,
  .page-header-box {
    margin-top: 0px !important;
  }

  .page-header-box {
    height: 150px !important;
    background-color: #f2f2f2 !important;
    color: black !important;
    font-size: xx-large;
  }

  .prop-td-sm {
    margin-left: 15px;
  }

  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    padding: 0 0px !important;
  }

  #kt_content_container .container {
    margin-top: 0px !important;
  }

  .bgi-h-resp {
    min-height: 330px !important;
  }

  .p-20-custom {
    padding: 20px;
  }

  .page-main-container {
    margin-top: 0px !important;
    padding: 0px !important;
  }
}

.custom-logo {
  background-color: #f2f2f2 !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 440px) and (max-width: 915px) {

  /* Your CSS rules for screen widths between 400px and 500px go here */
  .custom-mw-100 {
    max-width: 100% !important;
  }

  .bgi-h-resp {
    min-height: 650px !important;
  }

  .custom-w-100 {
    width: 100%;
  }

  .custom-fs-lg {
    font-size: x-large !important;
  }

  .min-h-150px {
    min-height: 420px !important;
  }
}

.card img.property-image {
  max-height: auto;
}

/* Media query */
@media (max-width: 767.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .card .property-image {
    max-height: 200px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .card .property-image {
    max-height: 200px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .card .property-image {
    max-height: 200px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .card .property-image {
    max-height: 200px;
  }
}
