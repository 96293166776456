//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: if(isDarkMode(), #77b829, #77b829);
$primary-active: if(isDarkMode(), #77b829, #77b829);
$primary-light: if(isDarkMode(), #77b829, #f1faff);
$primary-inverse: #ffffff;

// Success
$success: if(isDarkMode(), #0bb783, #50cd89);
$success-active: if(isDarkMode(), #04aa77, #47be7d);
$success-light: if(isDarkMode(), #1c3238, #e8fff3);
$success-inverse: #ffffff;

// Info
$info: if(isDarkMode(), #8950fc, #7239ea);
$info-active: if(isDarkMode(), #7337ee, #5014d0);
$info-light: if(isDarkMode(), #2f264f, #f8f5ff);
$info-inverse: #ffffff;

// Danger
$danger: if(isDarkMode(), #f64e60, #f1416c);
$danger-active: if(isDarkMode(), #ee2d41, #d9214e);
$danger-light: if(isDarkMode(), #3a2434, #fff5f8);
$danger-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #ffa800, #ffc700);
$warning-active: if(isDarkMode(), #ee9d01, #f1bc00);
$warning-light: if(isDarkMode(), #392f28, #fff8dd);
$warning-inverse: #ffffff;

$prlightgrey: #f2f2f2;

// Aside
$aside-config: (
  z-index: 101,
  // Aside's z-index property
  transition-speed: 0.3s,
  // transition speed
  padding-x: 25px,
  menu-indention: 0.75rem,
  bg-color: $prlightgrey,
  logo-bg-color: darken(#646363, 2%),
  scrollbar-color: if(isDarkMode(), $gray-200, #3b3b64),
  scrollbar-hover-color: if(isDarkMode(), $gray-300, lighten(#3b3b64, 3%)),
  width: 265px,
  // Aside width for desktop mode
  minimized-width: 75px,
  // Aside minimized width for desktop mode
  box-shadow: if(isDarkMode(), none, 0 0 28px 0 rgba(82, 63, 105, 0.05)),
  // Aside box shadow
  minimized-hover-box-shadow: if(isDarkMode(), none, 5px 0px 10px rgba(70, 78, 95, 0.075))
    // Monimized hover box shadow,,
) !default;

